/* eslint-disable */
import "core-js/modules/es.string.starts-with";
import "core-js/modules/es.string.ends-with";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.from";
import "core-js/modules/es.object.entries";

if (NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach
}

if (!Object.entries)
	Object.entries = function(obj) {
		var ownProps = Object.keys(obj),
			i = ownProps.length,
			resArray = new Array(i) // preallocate the Array
		while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

		return resArray
	}

Number.isInteger =
	Number.isInteger ||
	function(value) {
		return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
	}


if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function closest(s) {
		let el = this

		do {
			if (el.matches(s)) return el
			el = el.parentElement || el.parentNode
		} while (el !== null && el.nodeType === 1)
		return null
	}
}
